import { FETCH_COMMENTS } from '@/actions/actionTypes';
import { innerFetchComments } from '@/actions/comment';
import { Comment } from '@/domains/firestore/comment';

export type CommentState = {
  error: {
    status: boolean;
    message: string | null;
  };
  commentList: Comment[];
};

type CommentActions = ReturnType<typeof innerFetchComments>;

const initialState: CommentState = {
  error: {
    status: false,
    message: null
  },
  commentList: []
};

const commentsReducer = (state = initialState, action: CommentActions) => {
  switch (action.type) {
    case FETCH_COMMENTS:
      return {
        ...state,
        commentList: action.payload.commentList
      };
    default:
      return state;
  }
};

export default commentsReducer;
