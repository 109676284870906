import {
  SET_CREDENTIAL_USER,
  RESET_CREDENTIAL_USER,
  UPDATE_USER,
  SET_CURRENT_USER
} from '@/actions/actionTypes';
import {
  innerSetCredentialUser,
  innerResetCredentialUser,
  innerUpdateUser,
  innerSetCurrentUser
} from '@/actions/user';
import { User } from '@/domains/firestore/user';

export type UserState = {
  error: {
    status: boolean;
    message: string | null;
  };
  user: User | null;
};

type UserActions = ReturnType<
  | typeof innerSetCredentialUser
  | typeof innerResetCredentialUser
  | typeof innerUpdateUser
  | typeof innerSetCurrentUser
>;

const initialState: UserState = {
  error: {
    status: false,
    message: null
  },
  user: null
};

const userReducer = (state = initialState, action: UserActions) => {
  switch (action.type) {
    case SET_CREDENTIAL_USER:
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload.user
      };
    case RESET_CREDENTIAL_USER:
      return {
        ...state,
        user: null
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user
      };
    default:
      return state;
  }
};

export default userReducer;
