import { combineReducers } from 'redux';
import menuReducer, { MenuState } from '@/reducers/menu';
import ideaReducer, { IdeaState } from '@/reducers/idea';
import shoppingReducer, { ShoppingState } from '@/reducers/shopping';
import menuTaxonomyReducer, { MenuTaxonomyState } from '@/reducers/menuTaxonomy';
import userReducer, { UserState } from '@/reducers/user';
import commentReducer, { CommentState } from '@/reducers/comment';

export type RootState = {
  menu: MenuState;
  idea: IdeaState;
  shopping: ShoppingState;
  menuTaxonomy: MenuTaxonomyState;
  user: UserState;
  comment: CommentState;
};

const rootReducer = combineReducers({
  menu: menuReducer,
  idea: ideaReducer,
  shopping: shoppingReducer,
  menuTaxonomy: menuTaxonomyReducer,
  user: userReducer,
  comment: commentReducer
});

export default rootReducer;
