import {
  FETCH_MENUS,
  FETCH_NEW_MENUS,
  FETCH_FEATURE_MENUS,
  FETCH_TAXONOMY_MENUS,
  FETCH_SAME_CATEGORY_MENUS,
  FETCH_FAVORITED_MENUS,
  FETCH_MENU,
  ERROR_MENU,
  RESET_MENUS
} from '@/actions/actionTypes';
import {
  innerFetchMenus,
  innerFetchNewMenus,
  innerFetchFeatureMenus,
  innerFetchTaxonomyMenus,
  innerFetchSameCategoryMenus,
  innerFetchFavoritedMenus,
  innerFetchMenu,
  innerErrorMenu,
  innerResetMenus
} from '@/actions/menu';
import { IMenu } from '@/@types/generated/contentful';

export type MenuState = {
  error: {
    status: boolean;
    message: string | null;
  };
  menuList: IMenu[];
  menuFavoriteList: IMenu[];
  menu: IMenu | null;
};

type MenuActions = ReturnType<
  | typeof innerFetchMenus
  | typeof innerFetchNewMenus
  | typeof innerFetchFeatureMenus
  | typeof innerFetchTaxonomyMenus
  | typeof innerFetchSameCategoryMenus
  | typeof innerFetchFavoritedMenus
  | typeof innerFetchMenu
  | typeof innerErrorMenu
  | typeof innerResetMenus
>;

const initialState: MenuState = {
  error: {
    status: false,
    message: null
  },
  menuList: [],
  menuFavoriteList: [],
  menu: null
};

const menuReducer = (state = initialState, action: MenuActions) => {
  switch (action.type) {
    case FETCH_MENUS:
    case FETCH_NEW_MENUS:
    case FETCH_FEATURE_MENUS:
    case FETCH_TAXONOMY_MENUS:
    case FETCH_SAME_CATEGORY_MENUS:
      return {
        ...state,
        menuList: action.payload.menuList
      };
    case FETCH_FAVORITED_MENUS:
      return {
        ...state,
        menuFavoriteList: action.payload.menuList
      };
    case FETCH_MENU:
      return {
        ...state,
        menu: action.payload.menu
      };
    case ERROR_MENU:
      return {
        ...state,
        error: {
          status: true,
          message: 'エラーが発生しました'
        }
      };
    case RESET_MENUS:
      return {
        ...state,
        menuList: [],
        menuFavoriteList: [],
        menu: null
      };
    default:
      return state;
  }
};

export default menuReducer;
