import {
  FETCH_IDEAS,
  FETCH_NEW_IDEAS,
  FETCH_FAVORITED_IDEAS,
  FETCH_IDEA,
  ERROR_IDEA,
  RESET_IDEAS
} from '@/actions/actionTypes';
import {
  innerFetchIdeas,
  innerFetchNewIdeas,
  innerFetchFavoritedIdeas,
  innerFetchIdea,
  innerErrorIdea,
  innerResetIdeas
} from '@/actions/idea';
import { IIdea } from '@/@types/generated/contentful';

export type IdeaState = {
  error: {
    status: boolean;
    message: string | null;
  };
  ideaList: IIdea[];
  ideaFavoriteList: IIdea[];
  idea: IIdea | null;
};

type IdeaActions = ReturnType<
  | typeof innerFetchIdeas
  | typeof innerFetchNewIdeas
  | typeof innerFetchFavoritedIdeas
  | typeof innerFetchIdea
  | typeof innerErrorIdea
  | typeof innerResetIdeas
>;

const initialState: IdeaState = {
  error: {
    status: false,
    message: null
  },
  ideaList: [],
  ideaFavoriteList: [],
  idea: null
};

const ideaReducer = (state = initialState, action: IdeaActions) => {
  switch (action.type) {
    case FETCH_IDEAS:
    case FETCH_NEW_IDEAS:
      return {
        ...state,
        ideaList: action.payload.ideaList
      };
    case FETCH_FAVORITED_IDEAS:
      return {
        ...state,
        ideaFavoriteList: action.payload.ideaList
      };
    case FETCH_IDEA:
      return {
        ...state,
        idea: action.payload.idea
      };
    case ERROR_IDEA:
      return {
        ...state,
        error: {
          status: true,
          message: 'エラーが発生しました'
        }
      };
    case RESET_IDEAS:
      return {
        ...state,
        ideaList: [],
        ideaFavoriteList: [],
        idea: null
      };
    default:
      return state;
  }
};

export default ideaReducer;
