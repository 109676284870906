import { createStore, applyMiddleware, AnyAction, Store } from 'redux';
import rootReducer, { RootState } from '@/reducers';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';

const middlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger();
  // @ts-expect-error Middleware<{}, any, Dispatch<AnyAction>>' 型の引数は 'ThunkMiddleware<{}, AnyAction, undefined> & { withExtraArgument<E>(extraArgument: E)' 型のパラメータに割り当てることができない。
  middlewares.push(logger);
}

const reducer = (state: Todo, action: AnyAction) => {
  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  } else {
    return rootReducer(state, action);
  }
};

const initStore = () => {
  return createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
};

// export type AppDispatch = ReturnType<typeof initStore>['dispatch'];
export type AppDispatch = ThunkDispatch<Todo, unknown, AnyAction>;
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;

export const wrapper = createWrapper<Store<RootState>>(initStore);
