import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import ReactDOM from 'react-dom';
import Head from 'next/head';
import Router from 'next/router';
import { useMount } from 'react-use';
import PageChange from '@/components/common/PageChange';
import * as Sentry from '@sentry/node';
import TagManager from 'react-gtm-module';
import { wrapper } from '@/store/configureStore';
import 'styles/scss/nextjs-material-kit-pro.scss';
import 'animate.css/animate.min.css';

if (process.env.ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN
  });
}

Router.events.on('routeChangeStart', () => {
  document.body.classList.add('body-page-transition');
  ReactDOM.render(<PageChange />, document.getElementById('page-transition'));
});
Router.events.on('routeChangeComplete', () => {
  // @ts-expect-error HTMLElement | null' 型の引数は 'Element | DocumentFragment' 型のパラメータに割り当てることができません。
  ReactDOM.unmountComponentAtNode(document.getElementById('page-transition'));
  document.body.classList.remove('body-page-transition');
});
Router.events.on('routeChangeError', () => {
  // @ts-expect-error HTMLElement | null' 型の引数は 'Element | DocumentFragment' 型のパラメータに割り当てることができません。
  ReactDOM.unmountComponentAtNode(document.getElementById('page-transition'));
  document.body.classList.remove('body-page-transition');
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useMount(() => {
    if (process.env.ENVIRONMENT === 'production' && process.env.GTM_ID) {
      TagManager.initialize({ gtmId: process.env.GTM_ID });
    }
  });
  return (
    <React.Fragment>
      <Head>
        <title>small dish</title>
        <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
      </Head>
      <Component {...pageProps} />
    </React.Fragment>
  );
};

export default wrapper.withRedux(MyApp);
