import { FETCH_MENUS_TAXONOMY, GET_MENUS_TAXONOMY_TITLES } from '@/actions/actionTypes';
import { innerFetchMenusTaxonomy, innerGetMenusTaxonomyTitles } from '@/actions/menuTaxonomy';
import { IMenuTaxonomy } from '@/@types/generated/contentful';

export type MenuTaxonomyState = {
  error: {
    status: boolean;
    message: string | null;
  };
  menuTaxonomyList: IMenuTaxonomy[];
  menuTaxonomyTitles: string[];
};

type MenuActions = ReturnType<typeof innerFetchMenusTaxonomy | typeof innerGetMenusTaxonomyTitles>;

const initialState: MenuTaxonomyState = {
  error: {
    status: false,
    message: null
  },
  menuTaxonomyList: [],
  menuTaxonomyTitles: []
};

const menuTaxonomyReducer = (state = initialState, action: MenuActions) => {
  switch (action.type) {
    case FETCH_MENUS_TAXONOMY:
      return {
        ...state,
        menuTaxonomyList: action.payload.menuTaxonomyList
      };
    case GET_MENUS_TAXONOMY_TITLES:
      return {
        ...state,
        menuTaxonomyTitles: action.payload.menuTaxonomyTitles
      };
    default:
      return state;
  }
};

export default menuTaxonomyReducer;
