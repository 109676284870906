import {
  FETCH_SHOPPINGS,
  FETCH_FEATURE_SHOPPINGS,
  FETCH_RELATION_SHOPPINGS,
  FETCH_SAME_CATEGORY_SHOPPINGS,
  FETCH_FAVORITED_SHOPPINGS,
  FETCH_SHOPPING,
  ERROR_SHOPPING,
  RESET_SHOPPINGS
} from '@/actions/actionTypes';
import {
  innerFetchShoppings,
  innerFetchFeatureShoppings,
  innerFetchRelationShoppings,
  innerFetchSameCategoryShoppings,
  innerFetchFavoritedShoppings,
  innerFetchShopping,
  innerErrorShopping,
  innerResetShoppings
} from '@/actions/shopping';
import { IShopping } from '@/@types/generated/contentful';

export type ShoppingState = {
  error: {
    status: boolean;
    message: string | null;
  };
  shoppingList: IShopping[];
  shoppingFavoriteList: IShopping[];
  shopping: IShopping | null;
};

type ShoppingActions = ReturnType<
  | typeof innerFetchShoppings
  | typeof innerFetchFeatureShoppings
  | typeof innerFetchRelationShoppings
  | typeof innerFetchSameCategoryShoppings
  | typeof innerFetchFavoritedShoppings
  | typeof innerFetchShopping
  | typeof innerErrorShopping
  | typeof innerResetShoppings
>;

const initialState: ShoppingState = {
  error: {
    status: false,
    message: null
  },
  shoppingList: [],
  shoppingFavoriteList: [],
  shopping: null
};

const shoppingReducer = (state = initialState, action: ShoppingActions) => {
  switch (action.type) {
    case FETCH_SHOPPINGS:
    case FETCH_FEATURE_SHOPPINGS:
    case FETCH_RELATION_SHOPPINGS:
    case FETCH_SAME_CATEGORY_SHOPPINGS:
      return {
        ...state,
        shoppingList: action.payload.shoppingList
      };
    case FETCH_FAVORITED_SHOPPINGS:
      return {
        ...state,
        shoppingFavoriteList: action.payload.shoppingList
      };
    case FETCH_SHOPPING:
      return {
        ...state,
        shopping: action.payload.shopping
      };
    case ERROR_SHOPPING:
      return {
        ...state,
        error: {
          status: true,
          message: 'エラーが発生しました'
        }
      };
    case RESET_SHOPPINGS:
      return {
        ...state,
        shoppingList: [],
        shoppingFavoriteList: [],
        shopping: null
      };
    default:
      return state;
  }
};

export default shoppingReducer;
